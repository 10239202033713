// -----------------------------------------------------------------
// Popin Event Component
class PopinEvent extends HTMLElement {
  constructor() {
    super();
    this.dialog = this.querySelector('.c-popin-event__dialog');
    this.closeButton = this.querySelector('[data-action="close"]');
    this.id = this.getAttribute('id') || '';
    this.startTimestamp = parseInt(this.getAttribute('data-start-timestamp') || 0);
    this.endTimestamp = parseInt(this.getAttribute('data-end-timestamp') || 0);

    // Check if this popin should be shown based on time and last seen
    this.checkVisibility();
  }
  connectedCallback() {
    // Binding handlers
    this.handleClose = this.close.bind(this);
    this.handleKeyDown = this.onKeyDown.bind(this);
    this.handleBackdropClick = this.onBackdropClick.bind(this);

    // Add event listeners
    if (this.closeButton) {
      this.closeButton.addEventListener('click', this.handleClose);
    }
    if (this.dialog) {
      this.dialog.addEventListener('click', this.handleBackdropClick);
    }
    document.addEventListener('keydown', this.handleKeyDown);
  }
  disconnectedCallback() {
    // Remove event listeners
    if (this.closeButton) {
      this.closeButton.removeEventListener('click', this.handleClose);
    }
    if (this.dialog) {
      this.dialog.removeEventListener('click', this.handleBackdropClick);
    }
    document.removeEventListener('keydown', this.handleKeyDown);
  }
  checkVisibility() {
    const now = Math.floor(Date.now() / 1000); // Current timestamp in seconds

    // Get seen popins from localStorage
    let seenPopins = {};
    try {
      const storedData = localStorage.getItem('popin-event-lastseen');
      if (storedData) {
        seenPopins = JSON.parse(storedData);
      }
    } catch (e) {
      console.error('Error reading popin data from localStorage', e);
    }

    // Check if this popin was seen in the last 24 hours
    const lastSeen = seenPopins[this.id];
    const twentyFourHoursInSeconds = 86400; // 24 hours in seconds
    const wasSeenRecently = lastSeen && now - parseInt(lastSeen) < twentyFourHoursInSeconds;

    // Show popin if:
    // 1. Now is between start and end timestamps
    // 2. User hasn't seen it in the last 24 hours
    if (this.id && this.startTimestamp <= now && now <= this.endTimestamp && !wasSeenRecently) {
      this.show();
    }
  }
  show() {
    if (!this.dialog) return;

    // Check if body already has modal or menu open
    const body = document.body;
    if (body.classList.contains('is-with-modal') || body.classList.contains('is-with-menu')) {
      return;
    }
    setTimeout(() => {
      if (typeof this.dialog.showModal === 'function') {
        this.dialog.showModal();
      } else {
        // Fallback for browsers not supporting <dialog>
        this.dialog.setAttribute('open', '');
      }
      document.body.classList.add('is-with-modal');
    }, 150);
  }
  hide() {
    if (this.dialog) {
      this.dialog.close();
      document.body.classList.remove('is-with-modal');
    }
  }
  close() {
    // Save last seen timestamp to localStorage
    if (this.id) {
      const now = Math.floor(Date.now() / 1000);
      const twentyFourHoursInSeconds = 86400; // 24 hours in seconds

      // Get existing popin data or create new object
      let seenPopins = {};
      try {
        const storedData = localStorage.getItem('popin-event-lastseen');
        if (storedData) {
          seenPopins = JSON.parse(storedData);
        }
      } catch (e) {
        console.error('Error reading popin data from localStorage', e);
      }

      // Update with current popin
      try {
        // Add current popin's timestamp
        seenPopins[this.id] = now;

        // Clean up old entries (older than 24 hours)
        for (const popinId in seenPopins) {
          if (popinId !== this.id && now - seenPopins[popinId] > twentyFourHoursInSeconds) {
            delete seenPopins[popinId];
          }
        }
        localStorage.setItem('popin-event-lastseen', JSON.stringify(seenPopins));
      } catch (e) {
        console.error('Error saving popin data to localStorage', e);
      }
    }
    this.hide();
  }
  onKeyDown(event) {
    // Close on Escape key
    if (event.key === 'Escape' && this.dialog && this.dialog.open) {
      this.close();
    }
  }
  onBackdropClick(event) {
    // Check if the click was directly on the dialog element (backdrop)
    // and not on one of its children
    if (event.target === this.dialog) {
      this.close();
    }
  }
}
customElements.define('popin-event', PopinEvent);